
	import * as Model from '@/Model';
	import PageBase from '@/Page/Base.vue';
	import { Component } from 'vue-property-decorator';
	import { sleep } from '@/Utility/Helpers';

	/**
	 * @author Matt Kenefick <matt.kenefick@buck.co>
	 * @package Page
	 * @project BunnyDragon
	 */
	@Component
	export default class PageBridge extends PageBase {
		/**
		 * @return number
		 */
		public get translateY(): number {
			return this.$store.state.currentMotionScrollRatio === -1 ? 0 : this.$store.state.currentMotionScrollRatio * 100 * 5; // 5 sections
		}

		/**
		 * @return void
		 */
		protected get interactionModel(): Model.Interaction {
			return new Model.Interaction({
				dialogue: [
					{
						character: 'bunny',
						characterPoses: {
							bunny: 'wowed',
							dragon: 'happy',
						},
						text: `Thank you, ${this.$store.state.userZodiac}! Now take a deep breath...`,
					},
					{
						character: 'dragon',
						characterPoses: {
							bunny: 'happy',
							dragon: 'excited-extra',
						},
						text: `Here comes your reading!`,
					},
				],
			});
		}

		/**
		 * @return Promise<void>
		 */
		protected async Handle_OnDialogueComplete(): Promise<void> {
			// Wait for dialogues to animate out
			await sleep(1000);

			// Go to the conversation starter
			this.$router.push({
				name: 'PageEpilogue',
			});
		}
	}
